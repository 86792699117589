
    import { defineComponent } from 'vue';
    import {ElMessageBox, ElMessage} from "element-plus/es";
    import { reactive } from 'vue';
    import { toRefs } from 'vue';
    import {MARKDOWN_ENGINE_BUILD_IN, MARKDOWN_ENGINE_VDITOR } from '@/constants/note';
    import useMarkdownLinkCopy from '@/composables/useMarkdownLinkCopy';
    import useFolders from "@/composables/useFolders";
    import {apiResponseErrorHandle} from "@/composables/errorHandler";
    import { FOLDERS, HOST, NOTES, NOTE_DUPLICATE, NOTE_RENAME } from '@/constants/api';
    import MoveDialog from '@/components/folders/MoveDialog.vue';

    export default defineComponent({
        name: 'Index',
        components: {
            MoveDialog
        },
        setup() {
            const state = reactive({
                moveDialogVisible: false,
                dialogNewNoteVisible: false,
                newNoteLoading: false,
                newNoteForm: {
                    engine: "1"
                },
                currentFolder: {
                    id: null, //If get a large number ID, must change ID as String.
                },
                folders: [],
                notes: [],
                tableData: [] as any[],
                recursiveParents: [],
                moveItem: {
                    id: null,
                    fileType: null
                }
            })

            const {copyMarkdownLink} = useMarkdownLinkCopy();

            const {getFoldersAndNotes, getRecursiveParents, createFolder} = useFolders();

            const listByParentFolderId = (parentFolderId: number | string | null = null) => {
                getFoldersAndNotes(parentFolderId).then(function (response) {
                    state.tableData = [];
                    state.tableData = state.tableData.concat(response.data.folders);
                    state.tableData = state.tableData.concat(response.data.notes);
                    state.folders = response.data.folders;
                    state.notes = response.data.notes;
                    state.currentFolder = response.data.currentFolder;
                }).catch(apiResponseErrorHandle('获取数据失败'));
            };

            const listRecursiveParents = (currentFolderId: number | string | null = null) => {
                getRecursiveParents(currentFolderId).then(function(response) {
                    state.recursiveParents = response.data.parents;
                }).catch(apiResponseErrorHandle('获取面包屑数据失败'));
            };

            const onMoved = () => {
                state.moveDialogVisible = false;
                listByParentFolderId(state.currentFolder.id);
            }

            const onCloseMoveDialog = () => {
                state.moveDialogVisible = false
            }

            return {
                ...toRefs(state),
                copyMarkdownLink,
                listByParentFolderId,
                listRecursiveParents,
                onMoved,
                onCloseMoveDialog,
                createFolder
            }
        },

        created() {
            // watch 路由的参数，以便再次获取数据
            this.$watch(
                () => this.$route.params,
                () => {
                    if (this.$route.path.startsWith("/folders")) {
                        this.listByRouteParam();
                    }
                },
                // 组件创建完后获取数据，
                // 此时 data 已经被 observed 了
                { immediate: true }
            );
            this.$watch(
                () => this.currentFolder,
                () => {
                    if (this.currentFolder.id != null) {
                        this.listRecursiveParents(this.currentFolder.id);
                    }
                },
                // 组件创建完后获取数据，
                // 此时 data 已经被 observed 了
                { immediate: true }
            )
        },

        methods: {
            listByRouteParam() {
                const parentIdParam = this.$route.params.parentFolderId;
                const parentId = Array.isArray(parentIdParam) ? parentIdParam[0] : parentIdParam;
                this.listByParentFolderId(parentId);
            },
            listRecursiveParentsByRouteParam() {
                const parentIdParam = this.$route.params.parentFolderId;
                const parentId = Array.isArray(parentIdParam) ? parentIdParam[0] : parentIdParam;
                this.listRecursiveParents(parentId);
            },
            changeDir(targetFolderId: number) {
                this.$router.push("/folders/" + targetFolderId);
            },

            visit(row: any) {
                if (row.fileType == 1) {
                    this.changeDir(row.id);
                } else {
                    this.showNote(row.id, row.engine);
                }
            },

            newFolderOpen() {
                const vm = this;
                ElMessageBox.prompt('请输入文件夹名称', '新建文件夹', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern:
                        /[\w]*/,
                    inputErrorMessage: '文件夹名称不可用',
                }).then(({value}) => {
                    const parentId = this.currentFolder.id;
                    this.createFolder(parentId, value).then(function (response) {
                        ElMessage({
                            showClose: true,
                            message: '创建成功',
                            type: 'success',
                        });
                        vm.listByParentFolderId(parentId);
                    }).catch(apiResponseErrorHandle('创建失败'));
                }).catch(() => {
                    return;
                })
            },

            edit(row: any) {
                if (row.fileType == 1) {
                    this.editFolder(row);
                } else {
                    this.editNote(row.id, row.engine);
                }
            },

            editFolder(row: any) {
                const vm = this;
                ElMessageBox.prompt('请输入文件夹名称', '编辑文件夹', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: row.name,
                    inputPattern:
                        /[\w]*/,
                    inputErrorMessage: '文件夹名称不可用',
                }).then(({value}) => {
                    const parentId = this.currentFolder.id;
                    this.axios.patch(FOLDERS, {
                        id: row.id,
                        name: value,
                        teamId: this.$store.state.currentTeam?.id
                    }).then(function (response) {
                        ElMessage({
                            showClose: true,
                            message: '修改成功',
                            type: 'success',
                        });
                        vm.listByParentFolderId(parentId);
                    }).catch(apiResponseErrorHandle('修改失败'));
                }).catch(() => {
                    return;
                })
            },

            editNote(noteId: number, engine = MARKDOWN_ENGINE_VDITOR) {
                let routerName = '';
                if (engine == MARKDOWN_ENGINE_VDITOR) {
                    routerName = 'editNoteByVditor';
                } else if (engine == MARKDOWN_ENGINE_BUILD_IN) {
                    routerName = 'editNote';
                } else {
                    routerName = 'editNoteByVditor';
                }
                this.$router.push({name: routerName, params: {noteId: noteId}});
            },

            renameNote(row: any) {
                const vm = this;
                ElMessageBox.prompt('笔记标题', '重命名', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: row.name,
                    inputPattern:
                        /[\w]*/,
                    inputErrorMessage: '标题不可用',
                }).then(({value}) => {
                    const parentId = this.currentFolder.id;
                    this.axios.patch(NOTE_RENAME, {
                        id: row.id,
                        name: value,
                        teamId: vm.$store.state.currentTeam?.id
                    }).then(function (response) {
                        ElMessage({
                            showClose: true,
                            message: '修改成功',
                            type: 'success',
                        });
                        vm.listByParentFolderId(parentId);
                    }).catch(apiResponseErrorHandle('修改失败'));
                }).catch(() => {
                    return;
                })
            },

            deleteItem(row: any) {
                const vm = this;
                const parentId = this.currentFolder.id;
                const resourceText = row.fileType == 1 ? '文件夹' : '笔记';
                ElMessageBox.confirm(
                    `是否删除${resourceText}？`,
                    '提示',
                    {
                        confirmButtonText: '是',
                        cancelButtonText: '否',
                        type: 'warning',
                    }).then(() => {
                        const resource = row.fileType == 1 ? 'folders' : 'notes';
                        const url = `${HOST}/${resource}/` + row.id;
                        this.axios.delete(url, {
                            params: {
                                teamId: this.$store.state.currentTeam?.id
                            }
                        }).then(function(response) {
                            ElMessage({
                                showClose: true,
                                message: '删除成功',
                                type: 'success',
                            });
                            vm.listByParentFolderId(parentId);
                        }).catch(apiResponseErrorHandle('删除失败'))
                    }).catch(() => {
                        return;
                    })
            },

            duplicate(row: any) {
                const vm = this;
                this.axios.post(NOTE_DUPLICATE, {
                    id: row.id,
                    teamId: this.$store.state.currentTeam?.id
                }).then(function(response) {
                    vm.insertAfter(response.data.note, row);
                    ElMessage({
                        showClose: true,
                        message: '复制成功',
                        type: 'success',
                    });
                }).catch(apiResponseErrorHandle('复制失败'))
            },

            insertAfter(newItem: any, item: any) {
                const itemIndex = this.tableData.findIndex((value, index, obj) =>
                    value.id === item.id && value.fileType === item.fileType);
                if (itemIndex === -1) {
                    return
                } else if (itemIndex === 0) {
                    const firstItem = this.tableData.shift()
                    this.tableData.unshift(newItem)
                    this.tableData.unshift(firstItem)
                } else {
                    this.tableData = this.tableData.slice(0, itemIndex + 1)
                        .concat(newItem, this.tableData.slice(itemIndex + 1));
                }
            },

            copyLink(row: any) {
                if (this.copyMarkdownLink(row, false)) {
                    ElMessage({
                        showClose: true,
                        message: '复制成功',
                        type: 'success'
                    });
                }
            },

            move(row: any) {
                this.moveItem = row;
                this.moveDialogVisible = true;
            },

            trash() {
                this.$router.push({ name: 'trash' });
            },

            newNote() {
                this.newNoteForm.engine = "1";
                this.newNoteLoading = false;
                this.dialogNewNoteVisible = true;
            },

            newNoteConfirm() {
                this.newNoteLoading = true;
                const vm = this;
                this.axios.post(NOTES, {
                    engine: parseInt(this.newNoteForm.engine),
                    parentId: this.currentFolder.id,
                    teamId: this.$store.state.currentTeam?.id
                }).then(function (response) {
                    vm.dialogNewNoteVisible = false;
                    const note = response.data.note;
                    if (note) {
                        note.fileType = 2;
                        vm.tableData.unshift(note);
                        ElMessage({
                            showClose: true,
                            message: '成功',
                            type: 'success',
                        });
                    }
                }).catch(apiResponseErrorHandle('新建笔记失败', () => {
                    vm.newNoteLoading = false;
                }))
            },

            showNote(noteId: number, engine = MARKDOWN_ENGINE_VDITOR) {
                let routerName = '';
                if (engine == MARKDOWN_ENGINE_VDITOR) {
                    routerName = 'showNoteByVditor';
                } else if (engine == MARKDOWN_ENGINE_BUILD_IN) {
                    routerName = 'showNote';
                } else {
                    routerName = 'showNoteByVditor';
                }
                this.$router.push({name: routerName, params: {noteId: noteId}});
            },
        }
    });
